<template>
  <div>
    <upgrade-plan></upgrade-plan>
    <!-- <CCard class="col-md-12 pb-5">
       <CRow class="p-3">
        <h4 class="color-light ml-2 page_title">
          <span class="ul_text">PL</span>AN INFO
        </h4>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Plan Name :</label>
        </CCol>
        <CCol>
          {{ plan_details.plan_name }}
        </CCol>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Total clicks :</label>
        </CCol>
        <CCol>
          {{ plan_details.total_clicks }}
        </CCol>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Receive clicks :</label>
        </CCol>
        <CCol>
          {{ plan_details.receive_clicks }}
          <span
            class="ml-2"
            style="color: red; font-weight: 100"
            v-if="plan_details.receive_clicks > plan_details.total_clicks"
            >( Click limit exceeded )</span
          >
        </CCol>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Plan Type :</label>
        </CCol>
        <CCol>
          {{ plan_details.type }}
        </CCol>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Validity :</label>
        </CCol>
        <CCol>
          {{ plan_details.validity }}
        </CCol>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Days Left :</label>
        </CCol>
        <CCol>
          <span v-if="findleftDays(new Date(), plan_details.expiry_date) < 0">
            Expired
          </span>
          <span v-else>
            {{ findleftDays(new Date(), plan_details.expiry_date) }}
          </span>
        </CCol>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Expire Date:</label>
        </CCol>
        <CCol>
          {{ changeDateFormate(plan_details.expiry_date) }}
        </CCol>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Update Date :</label>
        </CCol>
        <CCol>
          {{ changeDateFormate(plan_details.updated_date) }}
        </CCol>
      </CRow>
      <CRow class="col-md-12 col-12">
        <CCol class="col-md-2">
          <label>Created Date :</label>
        </CCol>
        <CCol>
          {{ changeDateFormate(plan_details.created_date) }}
        </CCol>
      </CRow>
    </CCard> -->
  </div>
</template>

<script>
import "sweetalert2/src/sweetalert2.scss";
import DashboardService from "./../../services/dashboardController";
import UpgradePlan from '../../components/UpgradePlan.vue';
export default {
  components: {
    UpgradePlan
  },
  data() {
    return {
      plan_details: {},
    };
  },
  async mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true"
    ) {
      this.$router.push("/");
    } else {
      // this.getData();
    }
  },
  methods: {
    async getData() {
      let response = await DashboardService.getplandetails();
      if (response.result) {
        this.plan_details = response.message;
      }
    },
    changeDateFormate(d) {
      var p = new Date(d);
      return p.toLocaleDateString("en-US", {
        day: "numeric",
        year: "numeric",
        month: "long",
      });
    },
    treatAsUTC(date) {
      var result = new Date(date);
      result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
      return result;
    },
    findleftDays(startDate, endDate) {
      var millisecondsPerDay = 24 * 60 * 60 * 1000;
      var t =
        Math.floor(this.treatAsUTC(endDate) / millisecondsPerDay) -
        Math.floor(this.treatAsUTC(startDate) / millisecondsPerDay);
      return t;
    },
  },
};
</script>

<style scoped>
.card_ui {
  /* background-color: #cac9c9ab; */
  font-size: large;
  font-weight: 600;
  color: #3c4b64cf;
}
</style>
